import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '@environments/environment';
import { AuthService } from '@services/auth/auth.service';
import { Observable, catchError } from 'rxjs';

@Injectable()
export class ApiInterceptor implements HttpInterceptor
{
    constructor(
        private injector: Injector,
    )
    {

    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>>
    {
        if (!request.url.startsWith('/api') && !request.url.startsWith('/keycloak') && !request.url.startsWith(environment.api_host)) return next.handle(request);

        if (request.url.startsWith(environment.api_host))
        {
            return this.handleOwnedApiRequest(request, next);
        }

        const clonedReq = request.clone({
            url: request.url.includes(environment.api_host) ? request.url : environment.api_host + request.url,
            withCredentials: true
        });

        return this.handleOwnedApiRequest(clonedReq, next);
    };

    private handleOwnedApiRequest(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>>
    {
        return next.handle(req).pipe(
            catchError(async (err) =>
            {
                if (err.status === 0 || err.status === 503 || err.status === 504)
                {
                    const angularRouter = this.injector.get<Router>(Router);
                    angularRouter.navigate([ '/maintenance' ]);
                }

                if (err.status === 401)
                {
                    const authService = this.injector.get<any>(AuthService);
                    if (authService.LoginState)
                    {
                        let state = true;

                        try
                        {
                            await authService.check();

                            state = authService.LoginState;
                        }
                        catch (e)
                        {
                            state = false;
                        }

                        if (!state)
                        {
                            const angularRouter = this.injector.get<Router>(Router);
                            authService.logout();
                            angularRouter.navigate([ '/' ]);
                        }
                    }
                }

                throw err;
            })
        );
    }
}
