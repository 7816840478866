import { isPlatformBrowser } from '@angular/common';
import { inject, PLATFORM_ID } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { AuthService } from '@services/auth/auth.service';
import { tap } from 'rxjs/operators';

export const authGuardFn: CanActivateFn = (route, state) =>
{
    const platformId = inject(PLATFORM_ID);

    if (!isPlatformBrowser(platformId))
    {
        return true;
    } else
    {

        const authService = inject(AuthService);
        const router = inject(Router);

        return authService.authState$.pipe(
            tap((_state: boolean) =>
            {
                if (!_state)
                {
                    router.navigateByUrl('/');
                }

                return !_state;
            })
        );
    }
}

// canActivateChild(
//     childRoute: ActivatedRouteSnapshot,
//     state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree
// {
//     return this.canActivate(childRoute, state);
// }
