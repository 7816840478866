import { isPlatformBrowser } from '@angular/common';
import { inject, PLATFORM_ID } from '@angular/core';
import
{
    CanActivateFn,
    Router
} from '@angular/router';
import { eUserRole } from '@common/api/user';
import { AuthService } from '@services/auth/auth.service';
import { sleep } from '@services/providers/utils';

export const adminGuardFn: CanActivateFn = async (route, state) =>
{
    const platformId = inject(PLATFORM_ID);

    if (!isPlatformBrowser(platformId))
    {
        return true;
    } else
    {
        const authService = inject(AuthService);
        const router = inject(Router);

        let info = authService.userInfo$.getValue();

        while (info === undefined)
        {
            await sleep(1000);
            info = authService.userInfo$.getValue();
        }

        const userInfo = authService.UserInfo;

        if (userInfo?.role !== eUserRole.admin)
        {
            router.navigateByUrl('');
        }

        return userInfo!.role === eUserRole.admin;
    }
}

// canActivateChild(
//     childRoute: ActivatedRouteSnapshot,
//     state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
//     return this.canActivate(childRoute, state);
// }
