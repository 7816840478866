import {Routes} from "@angular/router";
import {WalletMigrationComponent} from "./pages/wallet-migration/wallet-migration.component";

export const adminRoutes: Routes = [
    {
        path: '', loadComponent: () => import('./pages/admin/admin.component').then(m => m.AdminComponent),
        children: [
            {path: '', redirectTo: 'dashboard', pathMatch: 'full'},
            {
                path: 'dashboard',
                loadComponent: () => import('./pages/dashboard/dashboard.component').then(m => m.DashboardComponent)
            },
            {
                path: 'users-tier',
                loadComponent: () => import('./pages/users-tier/users-tier.component').then(m => m.UsersTierComponent)
            },
            {
                path: 'sales',
                loadComponent: () => import('./pages/sales/sales/sales.component').then(m => m.SalesComponent),
                pathMatch: 'full'
            },
            {
                path: 'sales/:id',
                loadComponent: () => import('./pages/sales/sale-details/sale-details.component').then(m => m.SaleDetailsComponent)
            },
            {
                path: 'users',
                loadComponent: () => import('./pages/users/users.component').then(m => m.UsersComponent),
                pathMatch: 'full'
            },
            {
                path: 'staking',
                loadComponent: () => import('./pages/staking/staking.component').then(m => m.StakingComponent)
            },
            {
                path: 'kyc',
                loadComponent: () => import('./pages/kyc/kyc.component').then(m => m.KycComponent)
            },
            {
                path: 'user/wallet-change/:userId/:wallet',
                loadComponent: () => import('./pages/wallet-migration/wallet-migration.component').then(m => m.WalletMigrationComponent)
            }
        ]
    },
];
